import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, redirect } from "react-router-dom";
import LinkedInCallback from "./components/LinkedInCallback";
import Login from "./pages/Login";
import Registration from "./pages/Registration";
import Dashboard from "./pages/Dashboard";
import CreateAIAssistantTab from "./components/CreateAIAssistantTab";
import MainLayout from "./components/MainLayout";
import "./index.css";
import { Toaster } from 'react-hot-toast';
import VerificationEmail from './pages/VerificationEmail';
import DetailsPage from './pages/DetailsPage';
import LoginPage from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import AccountSettings from './pages/AccountSettings';
import { UserProvider } from "./redux/UserContext";
import IntegrateAIAssistant from './pages/IntegrateAIAssistant';
import ChatHistory from './pages/ChatHistory';
import axios from "axios";

function App() {
  const nodeAppUrl = process.env.REACT_APP_NODE_API_URL;

  useEffect(() => {
    const refreshToken = async () => {
      try {
        await axios.post(`${nodeAppUrl}/api/refresh-token`, {}, { withCredentials: true });
      } catch (error) {
        console.error("Failed to refresh token:", error);
      }
    };

    refreshToken();

    const intervalId = setInterval(refreshToken, 500000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <UserProvider>
      <Router>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/register" element={<Registration />} />
          <Route path="/linkedin-callback" element={<LinkedInCallback />} />
          <Route path="/dashboard" element={<MainLayout><Dashboard /></MainLayout>} />
          <Route path="/create-ai-assistant" element={<MainLayout><CreateAIAssistantTab /></MainLayout>} />
          <Route path="/integrate-ai-assistant" element={<MainLayout><IntegrateAIAssistant /></MainLayout>} />
          <Route path="/verify-email" element={<VerificationEmail />} />
          <Route path='/details-page' element={<DetailsPage />} />
          <Route path='/login' element={<LoginPage />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/reset-password' element={<ResetPassword />} /> 
          <Route path='/account-settings' element={<MainLayout><AccountSettings /></MainLayout>} />
          <Route path="/chat-history" element={<MainLayout><ChatHistory /></MainLayout>} />
        </Routes>
          <Toaster position="top-right" toastOptions={{ className: 'react-hot-toast' }} />
      </Router>
    </UserProvider>
  );
}

export default App;
