import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import toast from 'react-hot-toast';
import MenuItem from '@mui/material/MenuItem';
import { useTheme, styled } from '@mui/material/styles';
import AuthIllustrationWrapper from '../auth/AuthIllustrationWrapper';
import BlankLayout from '../@core/layouts/BlankLayout';

const CustomTextField = styled(TextField)({
  '& .MuiInputLabel-root': {
    '&.Mui-focused': {
      color: '#696cff', // Label color when focused
      '& .MuiFormLabel-asterisk': {
        color: '#696cff', // Asterisk color when focused
      },
    },
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#696cff', // Border color when focused
      boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
    },
  },
});

const DetailsPage = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [selectedCountryCode, setSelectedCountryCode] = useState('+1');
  const [businessName, setBusinessName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [countryCodes, setCountryCodes] = useState([]);
  const [filteredCountries, setFilteredCountries] = useState([]);

  const theme = useTheme();
  const navigate = useNavigate();
  const nodeAppUrl = process.env.REACT_APP_NODE_API_URL;
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  useEffect(() => {
    const checkLoginStatus = async () => {
        try {
            const response = await axios.post(`${nodeAppUrl}/api/isTokenExists`, {}, {
                withCredentials: true,
            });

            if (response.status === 200) {
                console.log(response.data.message);
                navigate('/dashboard');
            }
        } catch (error) {
            console.error("Login status check failed:", error.response?.data?.message || error.message);
        }
    };
      checkLoginStatus();
  }, [navigate]);
  useEffect(() => {
    axios.get('https://restcountries.com/v3.1/all')
      .then(response => {
        const countries = response.data.map(country => ({
          label: country.name.common,
          code: country.idd.root + (country.idd.suffixes ? country.idd.suffixes[0] : '')
        }));
        setCountryCodes(countries);
        setFilteredCountries(countries);
      })
      .catch(error => console.error('Error fetching country codes:', error));
  }, []);

  useEffect(() => {
    const filtered = countryCodes
      .filter(country =>
        country.label.toLowerCase().startsWith(searchQuery.toLowerCase()) ||
        country.code.startsWith(searchQuery)
      )
      .concat(
        countryCodes.filter(country =>
          !country.label.toLowerCase().startsWith(searchQuery.toLowerCase()) &&
          !country.code.startsWith(searchQuery) &&
          (country.label.toLowerCase().includes(searchQuery.toLowerCase()) ||
            country.code.includes(searchQuery))
        )
      );
    setFilteredCountries(filtered);
  }, [searchQuery, countryCodes]);

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const response = await axios.get(`${nodeAppUrl}/api/verify-generate-email-token?token=${token}`);
        console.log(response.data);
      } catch (error) {
        setError(true);
      }
    };
    verifyToken();
  }, [token, nodeAppUrl]);

  const handleSubmit = async e => {
    e.preventDefault();
    if (phoneNumber) {
      if (phoneNumber.length >= 6 && phoneNumber.length <= 10) {
        setPhoneNumber(phoneNumber.trim());
      } else {
        toast.error('Invalid Phone Number');
        return;
      }
    }

    try {
      const response = await fetch(`${nodeAppUrl}/api/user-details`, {
        method: 'POST',
        body: JSON.stringify({
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          businessName: businessName.trim(),
          phoneNumber: phoneNumber.trim(),
          countryCode: selectedCountryCode.trim(),
          token: token ? token.trim() : ''
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        },
        credentials: 'include'
      });

      const responseData = await response.json();
      console.log(responseData);
      navigate('/dashboard');
    } catch (error) {
      console.error(error);
    }
  };

  const handleNavigateToAiDeskerPage = () => {
    window.open("https://www.aidesker.com/", "_blank")
  }

  return (
    <div>
      {error ? (
        <Box className='flex min-h-screen items-center justify-center'>
          <AuthIllustrationWrapper>
            <Card sx={{ height: '300px', width: '400px' }}>
              <CardContent sx={{ p: `${theme.spacing(1, 3, 4)} !important` }}></CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: '100%', height: '200px' }}>
                <img
                  src='/images/newaiDeskerlogo.png'
                  alt='app-brand'
                  className='app-brand-logo demo rounded'
                  onClick={handleNavigateToAiDeskerPage}
                  style={{ width: '55%', height: '25%', objectFit: 'cover', cursor: 'pointer' }}
                 
                />
                <Typography sx={{ mt: 6, color: 'text.secondary', textAlign: 'center', fontSize: '150%' }}>
                  Page Expired
                </Typography>
              </Box>
            </Card>
          </AuthIllustrationWrapper>
        </Box>
      ) : (
        <Box className='flex min-h-screen items-center justify-center'>
          <AuthIllustrationWrapper>
            <Card sx={{ borderRadius: '8px', boxShadow: '0px 2px 10px 0px rgba(50, 71, 92, 0.1)' }}>
              <CardContent sx={{ p: `${theme.spacing(7, 5, 7)} !important` }}>
                <Box sx={{ mb: 2, mt: -1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <img
                    src='/images/newaiDeskerlogo.png'
                    alt='app-brand'
                    className='app-brand-logo demo rounded'
                    style={{ width: '55%', height: '25%', objectFit: 'cover', cursor: 'pointer' }}
                    onClick={handleNavigateToAiDeskerPage}
                  />
                </Box>
                <Typography className='secondary-content' sx={{ pb: 3, textAlign: 'center'}}>
                  Please provide all necessary information
                </Typography>
                <form autoComplete='off' onSubmit={handleSubmit}>
                  <CustomTextField
                    autoFocus
                    fullWidth
                    size='small'
                    sx={{ mb: 3 }}
                    label='First name'
                    placeholder='john'
                    onChange={e => setFirstName(e.target.value)}
                    required
                  />
                  <CustomTextField
                    autoFocus
                    fullWidth
                    size='small'
                    sx={{ mb: 3 }}
                    label='Last name'
                    placeholder='doe'
                    onChange={e => setLastName(e.target.value)}
                    required
                  />
                  <CustomTextField
                    autoFocus
                    fullWidth
                    sx={{ mb: 3 }}
                    label='Business Name'
                    size='small'
                    placeholder='Flywheel Infotech'
                    onChange={e => setBusinessName(e.target.value)}
                    required
                  />
                  <Grid item xs={12} sm={6} style={{ display: 'flex', alignItems: 'center' }}>
                    <CustomTextField
                      autoFocus
                      fullWidth
                      size="small"
                      select
                      label="Country Code"
                      value={selectedCountryCode}
                      onChange={(e) => setSelectedCountryCode(e.target.value)}
                      variant="outlined"
                      style={{ width: '100px', marginRight: '8px', height: '56px' }}
                      onInput={(e) => setSearchQuery(e.target.value)}
                      SelectProps={{
                        renderValue: (value) => `${value}`,
                      }}
                    >
                      {filteredCountries.map((option) => (
                        <MenuItem key={option.code} value={option.code}>
                          {`(${option.code}) ${option.label}`}
                        </MenuItem>
                      ))}
                    </CustomTextField>
                    <CustomTextField
                      autoFocus
                      fullWidth
                      size="small"
                      sx={{ mb: 2 }}
                      label="Phone Number"
                      placeholder="0000000000"
                      type="number"
                      onChange={e => setPhoneNumber(e.target.value)}
                      inputProps={{ maxLength: 10 }}
                      style={{ width: 'calc(100% - 108px)' }}
                      required
                    />
                  </Grid>
                  <Button fullWidth className="primary-button" type='submit' variant='contained' sx={{ mb: 2, mt: 2, borderRadius: '8px' }}>
                    Submit
                  </Button>
                </form>
              </CardContent>
            </Card>
          </AuthIllustrationWrapper>
        </Box>
      )}
    </div>
  );
}

DetailsPage.getLayout = page => <BlankLayout>{page}</BlankLayout>;

export default DetailsPage;
