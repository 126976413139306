import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import InputLabel from '@mui/material/InputLabel'
import IconButton from '@mui/material/IconButton'
import CardContent from '@mui/material/CardContent'
import FormControl from '@mui/material/FormControl'
import OutlinedInput from '@mui/material/OutlinedInput'
import { styled, useTheme } from '@mui/material/styles'
import InputAdornment from '@mui/material/InputAdornment'
import Icon from "../@core/components/icon";
import BlankLayout from "../@core/layouts/BlankLayout";
import AuthIllustrationWrapper from "../auth/AuthIllustrationWrapper";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import toast from 'react-hot-toast'

const LinkStyled = styled(Link)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  textDecoration: "none",
  justifyContent: "center",
}));


const CustomTextField = styled(FormControl)({
  '& .MuiInputLabel-root': {
    '&.Mui-focused': {
      color: '#696cff', // Label color when focused
      '& .MuiFormLabel-asterisk': {
        color: '#696cff', // Asterisk color when focused
      },
    },
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#696cff', // Border color when focused
      boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
    },
  },
});

const ResetPassword = () => {
  const [email, setEmail] = useState('')
  const nodeAppUrl = process.env.REACT_APP_NODE_API_URL;
  const [values, setValues] = useState({
    newPassword: '',
    showNewPassword: false,
    confirmNewPassword: '',
    showConfirmNewPassword: false
  })
  const [error, setError] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  const theme = useTheme();
  const navigate = useNavigate();

  const handleNewPasswordChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const handleClickShowNewPassword = () => {
    setValues({ ...values, showNewPassword: !values.showNewPassword })
  }

  const handleConfirmNewPasswordChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const handleClickShowConfirmNewPassword = () => {
    setValues({ ...values, showConfirmNewPassword: !values.showConfirmNewPassword })
  }

  useEffect(() => {
    const checkLoginStatus = async () => {
        try {
            const response = await axios.post(`${nodeAppUrl}/api/isTokenExists`, {}, {
                withCredentials: true,
            });

            if (response.status === 200) {
                console.log(response.data.message);
                navigate('/dashboard');
            }
        } catch (error) {
            console.error("Login status check failed:", error.response?.data?.message || error.message);
        }
    };
    checkLoginStatus();
  }, [navigate]);

  const handleSubmit = async e => {
    e.preventDefault()
    if (values.newPassword.trim() === '' || values.confirmNewPassword.trim() === '') {
      toast.error('Please fill in all required fields.')
      return
    }

    if (values.newPassword !== values.confirmNewPassword) {
      toast.error('Please make sure your passwords match.')
      return
    }

    try {
      const response = await fetch(`${nodeAppUrl}/api/reset-password`, {
        method: 'POST',
        body: JSON.stringify({
          token: token,
          password: values.newPassword.trim()
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        },
        credentials: 'include', 
      })
      const responseData = await response.json();
      console.log('responseData', responseData);
      if(response.ok){
        toast.success(responseData.message);
        navigate("/login")
      } else {
        toast.error(responseData.error)
      }
    } catch (error) {
      toast.error(error.message)
      console.error(error)
    }
  }

  const handleNavigateToAiDeskerPage = () => {
    window.open("https://www.aidesker.com/", "_blank")
  }

  return (
    <>
    {error ? (
        <Box className='flex min-h-screen items-center justify-center'>
          <AuthIllustrationWrapper>
            <Card sx={{ height: '300px', width: '400px', borderRadius: '8px', boxShadow: '0px 2px 10px 0px rgba(50, 71, 92, 0.1)' }}>
              <CardContent sx={{ p: `${theme.spacing(1, 3, 4)} !important` }}></CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: '100%', height: '200px' }}>
                <img
                  src='/images/newaiDeskerlogo.png'
                  alt='app-brand'
                  className='app-brand-logo demo rounded'
                  style={{ width: '55%', height: '25%', objectFit: 'cover', cursor: 'pointer' }}
                  onClick={handleNavigateToAiDeskerPage}
                />
                <Typography sx={{ mt: 6, color: 'text.secondary', textAlign: 'center', fontSize: '150%' }}>
                  Page Expired
                </Typography>
              </Box>
            </Card>
          </AuthIllustrationWrapper>
        </Box>
      ) : (
        <Box className='flex min-h-screen items-center justify-center'>
        <AuthIllustrationWrapper>
        <Card sx={{ borderRadius: '8px', boxShadow: '0px 2px 10px 0px rgba(50, 71, 92, 0.1)'}}>
        <CardContent sx={{ p: `${theme.spacing(5, 5, 5)} !important` }}>
              <Box sx={{ mb: 1.5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <img
                  src='/images/newaiDeskerlogo.png'
                  alt='app-brand'
                  className='app-brand-logo demo rounded'
                  style={{ width: '55%', height: '25%', objectFit: 'cover', cursor: 'pointer' }}
                  onClick={handleNavigateToAiDeskerPage}
                />
              </Box>
              <Typography className='primary-content' sx={{ pb: 1.5, textAlign: 'center' }}>
                Reset Password 🔒
              </Typography>
              {/* <Typography variant='subtitle1' sx={{ color: 'text.secondary', fontSize: '0.940rem' }}>
                for <strong>{email}</strong>
              </Typography> */}
              <form noValidate autoComplete='off' onSubmit={handleSubmit}>
                <CustomTextField size='small' sx={{ display: 'flex', mb: 3, mt: 4}}>
                  <InputLabel required htmlFor='auth-reset-password-new-password'>
                    New Password
                  </InputLabel>
                  <OutlinedInput
                    required
                    autoFocus
                    label='New Password'
                    value={values.newPassword}
                    id='auth-reset-password-new-password'
                    onChange={handleNewPasswordChange('newPassword')}
                    type={values.showNewPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          edge='end'
                          onClick={handleClickShowNewPassword}
                          onMouseDown={e => e.preventDefault()}
                          aria-label='toggle password visibility'
                        >
                          <Icon fontSize={20} icon={values.showNewPassword ? 'bx:show' : 'bx:hide'} />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </CustomTextField>
                <CustomTextField size='small' sx={{ display: 'flex', mb: 4 }}>
                  <InputLabel required htmlFor='auth-reset-password-confirm-password'>
                    Confirm Password
                  </InputLabel>
                  <OutlinedInput
                    required
                    label='Confirm Password'
                    value={values.confirmNewPassword}
                    id='auth-reset-password-confirm-password'
                    type={values.showConfirmNewPassword ? 'text' : 'password'}
                    onChange={handleConfirmNewPasswordChange('confirmNewPassword')}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          edge='end'
                          onMouseDown={e => e.preventDefault()}
                          aria-label='toggle password visibility'
                          onClick={handleClickShowConfirmNewPassword}
                        >
                          <Icon fontSize={20} icon={values.showConfirmNewPassword ? 'bx:show' : 'bx:hide'} />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </CustomTextField>
                <Button fullWidth size='small' type='submit' variant='contained' sx={{ mb: 4 }} className='primary-button'>
                  Set New Password
                </Button>
                <LinkStyled to="/login" sx={{ mb: 1 }}>
                <ChevronLeftIcon style={{ color: '#696cff', fill: '#696cff' }} />
                <span style={{ color: '#696cff' }}>Back to login</span>
              </LinkStyled>
              </form>
            </CardContent>
          </Card>
        </AuthIllustrationWrapper>
      </Box>
    )}
    </>      
  )
}

ResetPassword.getLayout = page => <BlankLayout>{page}</BlankLayout>

export default ResetPassword
