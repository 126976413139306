import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#696cff',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
'&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
},
// hide last border
'&:last-child td, &:last-child th': {
    border: 0,
},
}));

const Overlay = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    fontSize: '35px',
    zIndex: 10,
    borderRadius: 4
}));

const Container = styled('div')({
    position: 'relative',
});

const columns = [
  { id: 'name', label: 'Time', minWidth: 170 },
  { id: 'code', label: 'Name', minWidth: 170 },
  {
    id: 'population',
    label: 'Conversation',
    minWidth: 170,
    align: 'left',
  },
  {
    id: 'size',
    label: 'Lead Created',
    minWidth: 170,
    align: 'left',
  },
];

function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}

const rows = [
  createData('10:00 AM', 'John Doe', 'Asked about product details.', 'General inquiry about pricing.'),
  createData('10:30 AM', 'Jane Smith', 'Inquired about shipping times.', 'Question regarding delivery options.'),
  createData('11:00 AM', 'Michael Johnson', 'Discussed product features.', 'Inquiry about product specifications.'),
  createData('11:30 AM', 'Emily Davis', 'Asked about return policy.', 'Contact information request.'),
  createData('12:00 PM', 'James Brown', 'Interested in bulk order discounts.', 'Question regarding bulk purchases.'),
  createData('12:30 PM', 'Olivia Wilson', 'Asked about payment methods.', 'Inquiry about payment options.'),
  createData('01:00 PM', 'William Jones', 'Discussed warranty information.', 'Asked about warranty and support.'),
  createData('01:30 PM', 'Sophia Martinez', 'Inquired about store locations.', 'General inquiry regarding physical stores.'),
  createData('02:00 PM', 'Isabella Garcia', 'Asked about product availability.', 'Product inquiry for specific model.'),
  createData('02:30 PM', 'Liam Anderson', 'Interested in promotional offers.', 'Asked about ongoing promotions.'),
  createData('03:00 PM', 'Mia Rodriguez', 'Asked about customization options.', 'Product customization inquiry.'),
  createData('03:30 PM', 'Elijah Harris', 'Inquired about customer support hours.', 'Question regarding customer service.'),
  createData('04:00 PM', 'Mason Lee', 'Asked about product reviews.', 'Inquiry about customer feedback.'),
  createData('04:30 PM', 'Harper Clark', 'Discussed loyalty program benefits.', 'Inquiry about rewards program.'),
  createData('05:00 PM', 'Evelyn Lewis', 'Asked about return process.', 'Question regarding return and exchange.'),
  createData('05:30 PM', 'Lucas Walker', 'Interested in gift card options.', 'Asked about gift cards availability.'),
  createData('06:00 PM', 'Henry King', 'Inquired about account creation.', 'General inquiry about account setup.'),
  createData('06:30 PM', 'Amelia Wright', 'Asked about product compatibility.', 'Question regarding product use cases.'),
  createData('07:00 PM', 'Jack Scott', 'Discussed shipping costs.', 'Inquiry about international shipping.'),
  createData('07:30 PM', 'Ava Young', 'Asked about product comparisons.', 'Asked for comparison between two models.'),
];

export default function StickyHeadTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Container>
      <Overlay>
        Coming Soon
      </Overlay>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 600 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <StyledTableRow>
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <StyledTableRow role="checkbox" tabIndex={-1} key={row.code}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <StyledTableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </StyledTableCell>
                        );
                      })}
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Container>
  );
}
