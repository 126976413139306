import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { UserContext } from "../redux/UserContext";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Grid, Box } from "@mui/material";
import Badge from "@mui/material/Badge";
import { useNavigate } from "react-router-dom";
import { styled } from '@mui/material/styles'
import '../../src/index.css'

// Styled Grid component
const StyledGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    order: -1,
    display: 'flex',
    justifyContent: 'center'
  }
}))

// Styled component for the image
const Img = styled('img')(({ theme }) => ({
  right: 60,
  bottom: -1,
  height: 170,
  position: 'absolute',
  [theme.breakpoints.down('sm')]: {
    position: 'static'
  }
}))

const Dashboard = ({onTabChange}) => {
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);
  const [avatarUrl, setAvatarUrl] = useState(
    "/images/pages/profile-picture.png"
  );
  const [chatbotStatus, setChatbotStatus] = useState("");
  const navigate = useNavigate();
  const nodeAppUrl = process.env.REACT_APP_NODE_API_URL;
  const pythonAppUrl = process.env.REACT_APP_PYTHON_API_URL;
  const { userImage, setUserImage, setSelectedTab } = useContext(UserContext);

  const handleCreateAIButtonClick = () => {
    setSelectedTab('AI Assistant');
    navigate('/create-ai-assistant');
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${nodeAppUrl}/api/user`, {
          withCredentials: true,
        });

        setUserData(response.data);
        if(response.data.businessDetails){
          setChatbotStatus(response.data.businessDetails.chatbot_status);
        }
        if (response.data.personalDetails.userImage === "") {
          setAvatarUrl(avatarUrl);
          setUserImage(avatarUrl);
        } else if (
          response.data.personalDetails.userImage.startsWith("https")
        ) {
          setAvatarUrl(response.data.personalDetails.userImage);
          setUserImage(response.data.personalDetails.userImage);
        } else {
          const imageUrl = `${nodeAppUrl}/uploadUserProfiles/${response.data.personalDetails.userImage}`;
          setAvatarUrl(imageUrl);
          setUserImage(imageUrl);
        }
      } catch (error) {
        setError("Error fetching user data. Please try again later.");
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setAvatarUrl(userImage);
  }, [userImage]);

  if (error) {
    return <div>{error}</div>;
  }

  if (!userData) {
    return <div>please wait...</div>;
  }

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: chatbotStatus === "Pending" ? "red" : "green",
      color: chatbotStatus === "Pending" ? "red" : "green",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{mt: 2}} >
      {userData.businessDetails ? (
          <Grid item xs={12} md={4} className="flex items-center justify-center">
          <Card className="w-full min-h-50" sx={{ boxShadow: "0 2px 4px rgba(0,0,0,0.2)" }}>
            <CardContent>
              <CardMedia
                component="img"
                sx={{ objectFit: "contain" }}
                className="px-2 pt-3 h-20 w-full"
                image={`${pythonAppUrl}/files/partnerLogo/${userData.businessDetails.image_filename}`}
                alt={`${userData.businessName} logo`}
              />
              <Typography
                gutterBottom
                component="div"
                sx={{ display: 'flex', justifyContent: 'center', pt: 2 }}
                className="primary-heading"
              >
                {userData.businessName}
              </Typography>

              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ pr: { xs: 2, md: 2 }, mt: 2 }}
              >
                <Typography className="secondary-content">
                  Number of Chats
                </Typography>
                <Badge badgeContent={10} 
                  sx={{
                    '& .MuiBadge-badge': {
                      backgroundColor: '#696cff', // Change the badge color to #696cff
                      color: 'white',          // Optionally change the text color
                    }
                  }} 
                />
              </Box>

              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ pr: { xs: 2, md: 2 }, mt: 2 }}
              >
                <Typography className="secondary-content">
                  Number of Leads Created
                </Typography>
                <Badge badgeContent={4} 
                  sx={{
                    '& .MuiBadge-badge': {
                      backgroundColor: '#696cff',
                      color: 'white',
                    }
                  }}
                />
              </Box>

              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ pr: { xs: 1, md: 1 }, mt: 5 }}
              >
                <Box display="flex" alignItems="center">
                  <Typography sx={{ fontWeight: "bold", pr: 1 }}>Not live</Typography>
                  <StyledBadge
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    variant="dot"
                    className="m-3"
                  />
                </Box>
                <Button
                  size="small"
                  variant="contained"
                  sx={{ fontSize: 12, fontWeight: 700, ml:5 }}
                  onClick={handleCreateAIButtonClick}
                  className="primary-button"
                >
                  Edit
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      ) : (
        <Grid item xs={12} lg={8} sx={{ order: -1 }}>
          <Card sx={{ position: 'relative', boxShadow: "0 2px 4px rgba(0,0,0,0.2)"}}>
            <CardContent sx={{ py: theme => `${theme.spacing(5)} !important` }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={6} sx={{ textAlign: ['center', 'start'] }}>
                  <Typography className="primary-heading" sx={{ mb: 1 }}>
                    Hi, {userData.personalDetails.firstName} 🎉
                  </Typography>
                  <Typography className="content">
                    Build your AI Assistant in just a few minutes
                  </Typography>
                  <Button size='small' variant="contained" 
                      sx={{ mt: 4, fontSize: 12, fontWeight: 700}}
                      className="primary-button"
                      onClick={handleCreateAIButtonClick}>
                    Create AI Assistant
                  </Button>
                </Grid>
                <StyledGrid item xs={12} sm={6}>
                  <Img alt='Congratulations John' src={`/images/illustration-john-light.png`} />
                </StyledGrid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>
  );
};

export default Dashboard;
