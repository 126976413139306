//React
import { useState, useEffect } from "react";
import axios from "axios";

// ** Next Import
import { Link, useNavigate } from "react-router-dom";

// ** MUI Components
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import toast from "react-hot-toast";

// ** Icon Imports
import BlankLayout from "../@core/layouts/BlankLayout";
import AuthIllustrationWrapper from "../auth/AuthIllustrationWrapper";

const LinkStyled = styled(Link)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  textDecoration: "none",
  justifyContent: "center",
  // color: theme.palette.primary.main,
}));

const CustomTextField = styled(TextField)({
  '& .MuiInputLabel-root': {
    '&.Mui-focused': {
      color: '#696cff', // Label color when focused
      '& .MuiFormLabel-asterisk': {
        color: '#696cff', // Asterisk color when focused
      },
    },
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#696cff', // Border color when focused
      boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
    },
  },
});

const ForgotPassword = () => {
  
  const theme = useTheme();

  const [email, setEmail] = useState("");
  const nodeAppUrl = process.env.REACT_APP_NODE_API_URL;
  const navigate = useNavigate();

  useEffect(() => {
    const checkLoginStatus = async () => {
        try {
            const response = await axios.post(`${nodeAppUrl}/api/isTokenExists`, {}, {
                withCredentials: true,
            });

            if (response.status === 200) {
                console.log(response.data.message);
                navigate('/dashboard');
            }
        } catch (error) {
            console.error("Login status check failed:", error.response?.data?.message || error.message);
        }
    };

      checkLoginStatus();
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error("Invalid Email");
      return;
    }
    try {
      const response = await fetch(
        `${nodeAppUrl}/api/forgot-password`,
        {
          method: "POST",
          body: JSON.stringify({
            email: email.trim(),
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
					credentials: 'include',
        }
      )
			const responseData = await response.json();
			console.log(responseData)
			if(response.ok){
				toast.success(responseData.message)
			} else {
				toast.error(responseData.message)
			}
			
    } catch (error) {
      console.error(error);
    }
  };

  const handleNavigateToAiDeskerPage = () => {
    window.open("https://www.aidesker.com/", "_blank")
  }

  return (
    <Box className="flex min-h-screen items-center justify-center" >
      <AuthIllustrationWrapper>
        <Card
          sx={{
            borderRadius: "8px",
            boxShadow: "0px 2px 10px 0px rgba(50, 71, 92, 0.1)",
          }}
        >
          <CardContent sx={{ p: `${theme.spacing(3, 5, 4)} !important` }}>
            <Box
              sx={{
                mb: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src="/images/newaiDeskerlogo.png"
                alt="app-brand"
                className="app-brand-logo demo rounded"
                style={{ width: '55%', height: '25%', objectFit: 'cover', cursor: 'pointer' }}
                onClick={handleNavigateToAiDeskerPage}
              />
            </Box>

            <Typography className="primary-content" sx={{ pb: 1.5, textAlign: 'left' }}>
              Forgot Password? 🔒
            </Typography>

            <Typography className="secondary-content">
              Enter your email and we&prime;ll send you instructions to reset
              your password
            </Typography>
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <CustomTextField
                autoFocus
                required
                type="email"
                label="Email"
                size="small"
                sx={{ display: "flex", mb: 3, mt: 3 }}
                onChange={(e) => setEmail(e.target.value)}
              />
                <Button fullWidth size='small' type='submit' variant='contained' sx={{ mb: 3, borderRadius: '8px' }} className="primary-button">
                  Send reset link
                </Button>
                <LinkStyled to="/login">
                  <ChevronLeftIcon style={{ color: '#696cff', fill: '#696cff' }} />
                  <span style={{ color: '#696cff' }}>Back to login</span>
                </LinkStyled>
            </form>
          </CardContent>
        </Card>
      </AuthIllustrationWrapper>
    </Box>
  );
};
ForgotPassword.getLayout = (page) => <BlankLayout>{page}</BlankLayout>;

export default ForgotPassword;
