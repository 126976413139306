import React, { createContext, useState } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userImage, setUserImage] = useState(null);
  const [selectedTab, setSelectedTab] = useState('');

  return (
    <UserContext.Provider value={{ userImage, setUserImage, selectedTab, setSelectedTab }}>
      {children}
    </UserContext.Provider>
  );
};
