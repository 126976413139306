import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function LinkedInCallback() {
  const navigate = useNavigate();
  const [userProfile, setUserProfile] = useState(null);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(true);
  const nodeAppUrl = process.env.REACT_APP_NODE_API_URL;

  useEffect(() => {
    const handleLinkedInCallback = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');

      if (code) {
        try {
          const response = await axios.post(`${nodeAppUrl}/api/linkedin-auth`, { code });
          const { accessToken } = response.data;
          console.log('LinkedIn access token:', accessToken);

          const userResponse = await axios.get(`${nodeAppUrl}/api/linkedin-user-profile`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          console.log('User Profile:', userResponse.data);
          setUserProfile(userResponse.data);

          const registerResponse = await axios.post(`${nodeAppUrl}/api/OAuthEntry`, {
            email: userResponse.data.email,
            source: 'LinkedIn-OAuth',
            personalDetails: {
              firstName: userResponse.data.name,
              userImage: userResponse.data.picture
            },
          },
          {
            withCredentials: true
          }
          );

          console.log(registerResponse);
          if (registerResponse.status === 201) {
            navigate('/dashboard', { state: { email: userResponse.data.email } });
          } else if(registerResponse.data.message === "Email already exists") {
            navigate('/dashboard', { state: { email: userResponse.data.email } });
          } else {
            console.error('Registration failed:', registerResponse.data);
            setError('Registration failed. Please try again later.');
          }
        } catch (error) {
          setError(error.response.data.message || 'Error fetching user data. Please try again later.');
          console.error(error.response.data.message || 'Error fetching user data. Please try again later.');
        } finally {
          setProcessing(false);
        }
      } else {
        console.error('No authorization code found in URL');
        setError('No authorization code found in URL.');
        setProcessing(false);
      }
    };

    handleLinkedInCallback();
  }, [navigate]);

  if (error) {
    return <div>{error}</div>;
  }

  if (processing) {
    return <div>Processing LinkedIn authentication...</div>;
  }

  if (!userProfile) {
    return <div>No user profile data found.</div>;
  }

  return (
    <div>
      <h1>User Profile</h1>
      <p><strong>Name:</strong> {userProfile.name}</p>
      <p><strong>Email:</strong> {userProfile.email}</p>
      <p><strong>Profile Picture:</strong> <img src={userProfile.picture} alt="Profile" style={{ maxWidth: '200px' }} /></p>
    </div>
  );
}

export default LinkedInCallback;
