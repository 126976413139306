import React, { useState, useEffect } from 'react';
import qs from 'qs';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import {  Button, Card, Divider, FormControl, IconButton, InputAdornment, InputLabel, 
          OutlinedInput, TextField, Typography, FormControlLabel, Checkbox } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';

import Icon from '../@core/components/icon';
import BlankLayout from '../@core/layouts/BlankLayout';
import AuthIllustrationWrapper from '../auth/AuthIllustrationWrapper';

const sharedStyles = {
  '& .MuiInputLabel-root': {
    '&.Mui-focused': {
      color: '#696cff', // Label color when focused
      '& .MuiFormLabel-asterisk': {
        color: '#696cff', // Asterisk color when focused
      },
    },
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#696cff', // Border color when focused
      boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
    },
  },
};

const CustomTextField = styled(TextField)(({ theme }) => ({
  ...sharedStyles,
}));


const CustomFormControl = styled(FormControl)(({ theme }) => ({
  ...sharedStyles,
}));

const Login = () => {
  const [rememberMe, setRememberMe] = useState(true)
  const [showPassword, setShowPassword] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [gapiLoaded, setGapiLoaded] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const nodeAppUrl = process.env.REACT_APP_NODE_API_URL;
  const reactAppUrl = process.env.REACT_APP_API_URL;

  const clientId = '86k9o0v4nxe400';
  const clientSecret = 'mYOkr0vzfHWLrTlG';
  const redirectUri = `${reactAppUrl}/linkedin-callback`;

  useEffect(() => {
    const checkLoginStatus = async () => {
        try {
            const response = await axios.post(`${nodeAppUrl}/api/isTokenExists`, {}, {
                withCredentials: true,
            });

            if (response.status === 200) {
                console.log(response.data.message);
                navigate('/dashboard');
            }
        } catch (error) {
            console.error("Login status check failed:", error.response?.data?.message || error.message);
        }
    };
    checkLoginStatus();
  }, [navigate]);

  useEffect(() => {
    const loadGapiScript = () => {
      const script = document.createElement('script');
      script.src = 'https://apis.google.com/js/api.js';
      script.async = true;
      script.onload = () => {
        setGapiLoaded(true);
      };
      script.onerror = () => {
        console.error('Error loading GAPI script');
      };
      document.body.appendChild(script);
    };

    if (!window.gapi) {
      loadGapiScript();
    } else {
      setGapiLoaded(true);
    }
  }, []);

  const handlePasswordChange = e => {
    setPassword(e.target.value)
  }

  const handleSubmit = async e => {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailRegex.test(email)) {
      toast.error('Invalid Email')
      return
    }
    try {
      const response = await fetch(`${nodeAppUrl}/api/login`, {
        method: 'POST',
        body: JSON.stringify({
          email: email,
          password: password,
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        },
        credentials: 'include'
      })
      const responseStatus = response.status
      console.log(responseStatus)
      const responseData = await response.json()
      console.log('responseData', responseData)
      if(response.ok){
        navigate('/dashboard')
      } else {
        toast.error(responseData.message)
      }
    } catch (err) {
      console.error(err)
    }
  }
  const handleGoogleButtonClick = async () => {
    if (!gapiLoaded) {
      console.error('GAPI not loaded');
      return;
    }
    console.log('clicked');
    window.gapi.load('client:auth2', function () {
      window.gapi.client
        .init({
          client_id: '948358094283-id752ipdpblsro15nuafv2tbrjojs9vb.apps.googleusercontent.com',
          scope: 'email profile openid',
          plugin_name: 'chat',
        })
        .then(() => {
          signInWithGoogle();
        })
        .catch((error) => {
          console.error('Error initializing GAPI client', error);
        });
    });
  };

  const signInWithGoogle = async () => {
    try {
      const auth2 = window.gapi.auth2.getAuthInstance();
      const googleUser = await auth2.signIn();
      const profile = googleUser.getBasicProfile();

      const response = await axios.post(`${nodeAppUrl}/api/OAuthEntry`, {
        email: profile.getEmail(),
        source: 'Google-OAuth',
        personalDetails: {
          firstName: profile.getName(),
          userImage: profile.getImageUrl()
        },
      },
      {
        withCredentials: true
      }
    );
      console.log('response.data.message', response.data.message);

      if (response.status === 201) {
        navigate('/dashboard', { state: { email: profile.getEmail() } });
      } else if (response.data.message === 'Email already exists') {
        navigate('/dashboard', { state: { email: profile.getEmail() } });
      } else {
        console.error('Registration failed:', response.data);
      }
    } catch (error) {
      console.error('Error during registration:', error);
    }
  };

  const handleLinkedinButtonClick = () => {
    const params = {
      client_id: clientId,
      response_type: 'code',
      scope: 'openid,email,profile',
      redirect_uri: redirectUri,
    };
    const authUrl = `https://www.linkedin.com/oauth/v2/authorization?${qs.stringify(params)}`;
    window.location.href = authUrl;
  };

  const handleLinkedInCallback = () => {
    const code = new URLSearchParams(window.location.search).get('code');

    if (code) {
      fetch('https://www.linkedin.com/oauth/v2/accessToken', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          grant_type: 'authorization_code',
          code,
          redirect_uri: redirectUri,
          client_id: clientId,
          client_secret: clientSecret,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          const accessToken = data.access_token;
          fetch('https://api.linkedin.com/v2/me', {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
            .then((response) => response.json())
            .then((userData) => {
              const linkedinUserObject = {
                ID: userData.id,
                userName: `${userData.localizedFirstName} ${userData.localizedLastName}`,
                userImage: userData.profilePicture['displayImage~'].elements[0].identifiers[0].identifier,
                userEmailId: '', // You will need to make another request to fetch the email address
                source: 'LinkedIn-OAuth',
              };
              console.log('linkedinUserObject', linkedinUserObject);

              // Handle registration or login with linkedinUserObject here

              // For example, navigate to the dashboard if registration is successful
              // navigate('/dashboard');
            });
        })
        .catch((error) => {
          console.error('Error fetching access token:', error);
        });
    }
  };

  useEffect(() => {
    if (window.location.search.includes('code=')) {
      handleLinkedInCallback();
    }
  }, []);

  const handleNavigateToAiDeskerPage = () => {
    window.open("https://www.aidesker.com/", "_blank")
  }

  return (
    <Box className="flex min-h-screen items-center justify-center">
      <AuthIllustrationWrapper>
        <Card sx={{position: 'relative', boxShadow: "0 2px 4px rgba(0,0,0,0.2)", borderRadius: 2}}>
          <CardContent sx={{p: `${theme.spacing(3, 5, 4)} !important`,}}>
            <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <img
                src="/images/newaiDeskerlogo.png"
                alt="app-brand"
                className="app-brand-logo demo rounded"
                style={{ width: '55%', height: '25%', objectFit: 'cover', cursor: 'pointer' }}
                onClick={handleNavigateToAiDeskerPage}
              />
            </Box>
            <Typography className="primary-content" sx={{ textAlign: 'left', pb: 1}}>
              Welcome to aiDesker! 👋🏻
            </Typography>
            <Typography  className='secondary-content' sx={{ textAlign: 'left' }}>
            Please sign-in to your account and start the adventure
            </Typography>
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <CustomTextField
                autoFocus
                fullWidth
                size='small'
                id='email'
                sx={{ mb: 3, mt: 3 }}
                label='Business Email'
                placeholder='user@yourcompanyname.com'
                onChange={e => setEmail(e.target.value)}
                required
              />
              <CustomFormControl fullWidth sx={{ mb: 3 }} size='small'>
                <InputLabel htmlFor='auth-login-v2-password' required>
                  Password
                </InputLabel>
                <OutlinedInput
                  label='Password'
                  required
                  id='auth-login-v2-password'
                  onChange={handlePasswordChange}
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        edge='end'
                        onMouseDown={e => e.preventDefault()}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        <Icon icon={showPassword ? 'mdi:eye-outline' : 'mdi:eye-off-outline'} />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </CustomFormControl>
              <Box
                sx={{
                  mb: 2,
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between'
                }}
              >
                <FormControlLabel
                  label='Remember Me'
                  sx={{ '& .MuiFormControlLabel-label': { fontSize: '0.9375rem', color: 'rgb(136, 138, 141)' } }}
                  control={<Checkbox checked={rememberMe} onChange={e => setRememberMe(e.target.checked)} />}
                />
                <Link to='/forgot-password' className='link-text'>Forgot password?</Link>
              </Box>
              <Button fullWidth size='small' type='submit' className="primary-button" variant='contained' sx={{ mb: 3, borderRadius: '8px' }}>
                Sign in
              </Button>
              <Divider sx={{ my: `${theme.spacing(2)} !important` }}>or</Divider>
              <Box
                onClick={handleLinkedinButtonClick}
                sx={{
                  display: 'flex',
                  margin: 'auto',
                  mb: 3,
                  cursor: 'pointer',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                target="_blank"
              >
                <img
                  src="/images/linkedIn.png"
                  alt="LinkedIn"
                  style={{ width: '6%', height: 'auto', marginRight: '10px' }}
                />
                <Typography className='secondary-content'>Continue with Linkedin</Typography>
              </Box>
              <Box
                onClick={handleGoogleButtonClick}
                sx={{
                  display: 'flex',
                  margin: 'auto',
                  mb: 3,
                  cursor: 'pointer',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img
                  src="/images/Gmail.png"
                  alt="LinkedIn"
                  style={{ width: '6%', height: 'auto', marginRight: '10px' }}
                />
                <Typography className='secondary-content'>Continue with Google</Typography>
              </Box>
              <Box sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center'}}>
                <Typography className='secondary-content' sx={{pr: 1}}>
                  New on our platform?
                </Typography>
                <Typography>
                  <Link to="/register" className='link-text'>Create an account</Link>
                </Typography>
              </Box>
            </form>
          </CardContent>
        </Card>
      </AuthIllustrationWrapper>
    </Box>
  );
};

Login.getLayout = (page) => <BlankLayout>{page}</BlankLayout>;

export default Login;
