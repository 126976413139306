import React, { useState, useRef, Fragment, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import toast from "react-hot-toast";
import axios from "axios";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import { CardMedia } from "@mui/material";
import Skeleton from '@mui/material/Skeleton';

const CustomTextField = styled(TextField)({
  '& .MuiInputLabel-root': {
    '&.Mui-focused': {
      color: '#696cff',
      '& .MuiFormLabel-asterisk': {
        color: '#696cff',
      },
    },
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#696cff',
      boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
    },
  },
});

const DropzoneWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  cursor: "pointer",
  position: "relative",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  border: `2px dashed ${theme.palette.mode === "light"
    ? "rgba(93, 89, 98, 0.22)"
    : "rgba(247, 244, 254, 0.14)"
    }`,
  [theme.breakpoints.down("xs")]: {
    textAlign: "center",
  },
  "&:focus": {
    outline: "none",
  },
}));

const ButtonStyled = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    textAlign: 'center',
  },
}));

const ResetButtonStyled = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginLeft: 0,
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
}));

const FileInput = styled("input")({
  display: "none",
});

const FileDetails = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(1),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(1),
}));

const FilePreview = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginRight: theme.spacing(1),
}));

export default function CreateAIAssistantTab() {
  const [selectedPDFFile, setSelectedPDFFile] = useState(null);
  const [selectedLogoFile, setSelectedLogoFile] = useState(null);
  const [selectedLogoFileSrc, setSelectedLogoFileSrc] = useState(null);
  const fileInputRef = useRef(null);
  const [chatbotName, setChatbotName] = useState("");
  const [loading, setLoading] = useState(false)
  const [dataFetched, setDataFetched] = useState(false)
  const [isChanged, setIsChanged] = useState(false);
  const [haveScript, setHaveScript] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const navigate = useNavigate();

  const nodeAppUrl = process.env.REACT_APP_NODE_API_URL;
  const pythonAppUrl = process.env.REACT_APP_PYTHON_API_URL;
  const pythonPdfPath = process.env.REACT_APP_PYTHON_PDF_STORED_PATH;

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${nodeAppUrl}/api/user`, {
          withCredentials: true,
        });

        setUserEmail(response.data?.userId || '');
  
        if (response.data && response.data.businessDetails) {
          const businessDetails = response.data.businessDetails;
  
          setHaveScript(!!businessDetails?.chatbot_script);
          setChatbotName(response.data?.businessName || '');
  
          if (businessDetails.image_filename) {
            const logoUrl = `${pythonAppUrl}/files/partnerLogo/${businessDetails.image_filename}`;
            setSelectedLogoFileSrc(logoUrl);
  
            const logoFile = await getImageFromURL(logoUrl, businessDetails.image_filename);
            setSelectedLogoFile(logoFile);
          }
  
          if (businessDetails.pdf_filename) {
            const pdfUrl = `${pythonPdfPath}/${businessDetails.pdf_filename}`;
            const pdfFile = await getFileFromURL(pdfUrl, businessDetails.pdf_filename);
            setSelectedPDFFile(pdfFile);
          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setDataFetched(true);
      }
    };
    fetchUserData();
  }, []);

  async function getImageFromURL(url, filename) {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const file = new File([blob], filename, {
        type: response.headers.get("content-type"),
      });

      return file;
    } catch (error) {
      console.error("Error fetching image from URL:", error);
      throw error;
    }
  }

  async function getFileFromURL(url, filename) {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const file = new File([blob], filename, { type: "application/pdf" });

      return file;
    } catch (error) {
      console.error("Error fetching file from URL:", error);
      throw error;
    }
  }

  const handlePDFFileInputChange = (event) => {
    setIsChanged(true);
    const file = event.target.files[0];
    setSelectedPDFFile(file);
  };

  const handleDropzoneClick = () => {
    fileInputRef.current.click();
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleResetClick = () => {
    setSelectedLogoFile(null);
    setSelectedLogoFileSrc(null);
  };

  const handleChatbotNameChange = (event) => {
    setIsChanged(true);
    setChatbotName(event.target.value);
  };

  const handleLogoUpload = (event) => {
    setIsChanged(true);
    const file = event.target.files[0];
    setSelectedLogoFile(file);
  
    if (file) {
      const reader = new FileReader();
  
      reader.onload = (e) => {
        setSelectedLogoFileSrc(e.target.result);
      };
  
      reader.onerror = () => {
        toast.error("Error reading image file.");
      };

      reader.readAsDataURL(file);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer.files[0];
    setSelectedPDFFile(file);
  };

  const handleRemoveFile = () => {
    setSelectedPDFFile(null);
  };

  const handleSubmit = async () => {
    console.log("inside handle submit...");

    if (!isChanged) {
      console.log("inside, There is not changes here");
      navigate("/integrate-ai-assistant")
      return;
    }

    if (!selectedPDFFile || !selectedLogoFile || !chatbotName) {
      toast.error("Please fill all the required fields.");
      return;
    }

    const formData = new FormData();

    console.log("chatbotName on form", chatbotName);
    console.log("selectedPDFFile on form", selectedPDFFile);
    console.log("selectedLogoFile on form", selectedLogoFile);

    formData.append("chatbot_name", chatbotName);
    formData.append("user_email", userEmail);
    formData.append("pdfFile", selectedPDFFile);
    formData.append("imageFile", selectedLogoFile);
    setLoading(true);

    try {
      const response = await axios.post(
        `${pythonAppUrl}/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": "Zmx5d2hlZWxpbmZvdGVjaF9haURlc2tlcl9wcm9kdWN0X3dpZGdldA=="
          },
        }
      );

      if (response.data.result === "Files uploaded successfully") {
        try {
          const detailsResponse = await axios.post(
            `${nodeAppUrl}/api/business-details`,
            {
              businessName: response.data.chatbot_name,
              businessDetails: {
                pdf_filename: response.data.pdf_filename,
                image_filename: response.data.image_filename,
                chatbot_business_logo: response.data.chatbot_business_logo,
                chatbot_script: response.data.chatbot_script,
                chatbot_status: "Pending",
              },
            },
            {
              withCredentials: true,
            }
          );
          if (detailsResponse.data.message === "Business details updated successfully") {
            try {
              const sendingEmail = await axios.post(`${nodeAppUrl}/api/chatbot-created-email`, {
                userEmail: detailsResponse.data.user.userId,
                businessName: detailsResponse.data.user.businessName,
                pdf_filename: detailsResponse.data.user.businessDetails.pdf_filename,
                chatbot_script: detailsResponse.data.user.businessDetails.chatbot_script,
              },
                {
                  withCredentials: true,
                });
              console.log("sendingEmail", sendingEmail);
              toast.success("Chatbot created successfully!");
              navigate("/integrate-ai-assistant");
            } catch (error) {
              toast.error("Error while sending email to admin.");
              console.error("Error:", error);
            }
          }
        } catch (error) {
          toast.error("Error uploading business details.");
          console.error("Error:", error);
        }
      } else {
        toast.error("Error creating chatbot from aidesker.");
        console.error("Error creating chatbot from aidesker");
      }
    } catch (error) {
      toast.error("Error creating chatbot from aidesker.");
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Tabs value={0} onChange={() => navigate("/integrate-ai-assistant")} aria-label="tabs example"
        sx={{
          '& .MuiTabs-indicator': {
            backgroundColor: '#696cff',
          }
        }}
      >
        <Tab
          label="Create AI Assistant"
          sx={{
            color: 'gray',
            '&.Mui-selected': {
              color: '#696cff',
            }
          }}
        />
        <Tab
          disabled={!haveScript}
          label="Integrate AI Assistant"
          sx={{
            color: 'gray',
            '&.Mui-disabled': {
              color: 'lightgray',
            }
          }}
        />
      </Tabs>
      <Box sx={{ p: 2 }} sm={{ p: 1 }}>
        <Grid container spacing={2} alignItems="stretch">
          <Grid item xs={12} sm={6}>
            <Card
              style={{ boxShadow: "0 2px 4px rgba(0,0,0,0.2)", height: '100%' }}
              sx={{ mt: 3, display: 'flex', flexDirection: 'column', borderBottom: '0px' }}
            >
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography gutterBottom pb={2} className="primary-heading">
                  Business Logo
                </Typography>
                <Grid
                  container
                  sx={{ padding: "2px 10px" }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: { xs: '10px', sm: '0' },
                    }}
                  >
                    <CardMedia>
                      {dataFetched ? (
                        <Box
                          component="img"
                          sx={{ 
                            maxWidth: "100%", 
                            maxHeight: "86px", 
                            minHeight: "86px", 
                            padding: { xs: '10px', sm: '0', md: "5px" }
                          }}
                          src={selectedLogoFileSrc ?? "/images/pages/business-logo.png"}
                          alt="business-logo"
                        />
                      ) : (
                        <Skeleton
                          variant="rectangular"
                          width={180}
                          height={85}
                        />
                      )}
                    </CardMedia>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: { xs: "column", sm: "row" },
                      gap: { xs: 1 },
                    }}
                  >
                    <Tooltip
                      title="Choose an image in the .gif, .jpg or .png formats. We will proportionally resize the display of your image. We will not, however, resize the image's data. It is recommended that you keep your image close to the default image size (600x200) pixels"
                      arrow
                    >
                      <ButtonStyled
                        component="label"
                        variant="contained"
                        htmlFor="account-settings-upload-image"
                        className="primary-button"
                        sx={{ fontSize: 12, fontWeight: 700 }}
                      >
                        Upload
                        <FileInput
                          type="file"
                          id="account-settings-upload-image"
                          onChange={handleLogoUpload}
                        />
                      </ButtonStyled>
                    </Tooltip>
                    <ResetButtonStyled
                      variant="outlined"
                      onClick={handleResetClick}
                      sx={{ fontSize: 12, fontWeight: 700 }}
                    >
                      Reset
                    </ResetButtonStyled>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card
              style={{ boxShadow: "0 2px 4px rgba(0,0,0,0.2)", height: '100%' }}
              sx={{ mt: 3, display: 'flex', flexDirection: 'column' }}
            >
              <CardContent sx={{ flexGrow: 1, padding: "20px 20px" }}>
                {dataFetched ? (
                  <>
                    <Typography variant="h6" gutterBottom pb={6} className="primary-heading">
                      Chatbot Name
                    </Typography>
                    <CustomTextField
                      fullWidth
                      id="fullWidth"
                      label="Chatbot name"
                      value={chatbotName}
                      onChange={handleChatbotNameChange}
                      required
                      sx={{ mt: 0, mb: 0 }}
                    />
                  </>
                ) : (
                  <>
                    <Skeleton variant="text" width={160} height={40} />
                    <Skeleton variant="rectangular" width="100%" height={56} sx={{ mt: 2, mb: 2 }} />
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ p: 2 }} sm={{ p: 1 }}>
        <Grid container>
          <Grid item xs={12}>
            <Card style={{ boxShadow: "0 2px 4px rgba(0,0,0,0.2)" }} sx={{ mt: 1}}>
              <CardContent>
                <Typography variant="h6" gutterBottom className="primary-heading">
                  Upload Business Information
                </Typography>
                <DropzoneWrapper
                  onClick={handleDropzoneClick}
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                  sx={{ mt: 2 }}
                >
                  <input
                    type="file"
                    name="pdf"
                    accept="application/pdf"
                    ref={fileInputRef}
                    onChange={handlePDFFileInputChange}
                    multiple
                    style={{ display: "none" }}
                  />
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Box sx={{ marginLeft: { xs: 1, md: 10 } }}>
                        <Typography className="primary-content">
                          Drag and Drop your business information
                        </Typography>
                        <Typography className="secondary-content">
                          Allowed *.pdf format only
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {!dataFetched ? (
                        <Skeleton variant="rectangular" sx={{ width: { xs: 130, sm: 350 } }}  height={50} />
                      ) : (
                        selectedPDFFile ? (
                          <Box>
                            <FileDetails>
                              <FilePreview>
                                <PictureAsPdfIcon color="action" />
                              </FilePreview>
                              <Typography sx={{wordBreak: "break-word"}}>
                                {selectedPDFFile.name} ({Math.round(selectedPDFFile.size / 1024)} KB)
                              </Typography>
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleRemoveFile();
                                  fileInputRef.current.value = "";
                                }}
                              >
                                <CloseIcon color="action" />
                              </IconButton>
                            </FileDetails>
                          </Box>
                        ) : (
                          <Box>
                            <Button
                              component="label"
                              variant="contained"
                              tabIndex={-1}
                              startIcon={<CloudUploadIcon />}
                              className="primary-button"
                              sx={{ fontSize: 12, fontWeight: 700 }}
                            >
                              Upload files
                            </Button>
                          </Box>
                        )
                      )}
                    </Grid>
                  </Grid>
                </DropzoneWrapper>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "20px", marginLeft: "23px" }}>
          <Fragment>
            <div
              className="buttons"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <LoadingButton
                variant="contained"
                loading={loading}
                loadingIndicator="submitting..."
                onClick={handleSubmit}
                className="primary-button"
                sx={{
                  width: "10%",
                  textAlign: "center",
                  fontWeight: 700,
                  fontSize: 12,
                }}
              >
                Next
              </LoadingButton>
            </div>
          </Fragment>
        </Grid>
      </Box>
    </Box>
  );
}
