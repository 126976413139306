import React, {useState, useEffect} from "react";
import Box from '@mui/material/Box'
import { useNavigate, useLocation } from 'react-router-dom'
import AuthIllustrationWrapper from "../auth/AuthIllustrationWrapper";
import { Card, Typography } from "@mui/material";
import { styled, useTheme } from '@mui/material/styles';
import CardContent from '@mui/material/CardContent';
import BlankLayout from '../@core/layouts/BlankLayout';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import {Link} from "react-router-dom";


const LinkStyled = styled(Link)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  textDecoration: "none",
  justifyContent: "center",
}));

const VerifyEmail = () => {

  const location = useLocation();
  
  const [businessEmail, setBusinessEmail] = useState('');

    const theme = useTheme();
    const navigate = useNavigate();

    useEffect(() => {
      const params = new URLSearchParams(location.search);
      const email = params.get('email');
      setBusinessEmail(email);
    }, [location]);

    const handleNavigateToAiDeskerPage = () => {
      window.open("https://www.aidesker.com/", "_blank")
    }

  return(
    <Box className='flex min-h-screen items-center justify-center'>
      <AuthIllustrationWrapper>
        <Card sx={{ borderRadius: '8px', boxShadow: '0px 2px 10px 0px rgba(50, 71, 92, 0.1)'}}>
          <CardContent sx={{ p: `${theme.spacing(3, 5, 4)} !important` }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
              <img
                src='/images/newaiDeskerlogo.png'
                alt='app-brand'
                className='app-brand-logo demo rounded'
                style={{ width: '55%', height: '25%', objectFit: 'cover', cursor: 'pointer' }}
                onClick={handleNavigateToAiDeskerPage}
              />
                <Typography variant='h6' sx={{ mb: 1.5, mt: 3, textAlign: 'left', color: "#22303EE6", fontSize: '1.5rem' }}>
                  Great! verify your email ✉️
                </Typography>
                <Typography variant="subtitle1" sx={{ mb: 3, color: 'text.secondary', fontSize: '0.940rem' }}>
                  Check your inbox at: <strong>{businessEmail}</strong> and click the verification link inside to complete your registration.
                </Typography>
                {/* <Button fullWidth variant='contained' sx={{ mb: 3 }} onClick={handleResendVerifyEmail}>
                  Resend
                </Button> */}
                <LinkStyled to="/login">
                  <ChevronLeftIcon style={{ color: '#696cff', fill: '#696cff' }} />
                  <span style={{ color: '#696cff' }}>Back to login</span>
                </LinkStyled>
              </Box>
            
          </CardContent>
        </Card>
      </AuthIllustrationWrapper>
    </Box>
  )
    
}
VerifyEmail.getLayout = page => <BlankLayout>{page}</BlankLayout>

export default VerifyEmail;