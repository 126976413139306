import React, { useState, useRef, useEffect, useContext } from "react";
import { UserContext } from "../redux/UserContext";
import axios from "axios";
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import toast from "react-hot-toast";

const ImgStyled = styled("img")(({ theme }) => ({
  width: 120,
  height: "auto",
  maxHeight: "200px",
  borderRadius: theme.shape.borderRadius,
  marginRight: theme.spacing(6.25),
}));

const ButtonStyled = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    textAlign: "center",
  },
}));

const ResetButtonStyled = styled(Button)(({ theme }) => ({
  // marginLeft: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    marginLeft: 0,
    textAlign: "center",
    marginTop: theme.spacing(4),
  },
}));

const CustomTextField = styled(TextField)({
  '& .MuiInputLabel-root': {
    '&.Mui-focused': {
      color: '#696cff', // Label color when focused
      '& .MuiFormLabel-asterisk': {
        color: '#696cff', // Asterisk color when focused
      },
    },
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#696cff', // Border color when focused
      boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
    },
  },
});

const AccountSettings = () => {
  const initialFormData = {
    firstName: "",
    lastName: "",
    email: "",
    businessName: "",
    countryCode: "+1",
    phoneNumber: "",
    address: "",
    state: "",
    zipcode: "",
    country: "",
  };

  const [imageFile, setImageFile] = useState('/images/pages/profile-picture.png');
  const [file, setFile] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [formData, setFormData] = useState(initialFormData);
  const [initialFormDataFetched, setInitialFormDataFetched] =
    useState(initialFormData); // State to store fetched data
  const [initialProfile, setInitialProfile] = useState(imageFile);
  const [selectedCountryCode, setSelectedCountryCode] = useState(
    formData.countryCode
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [countryCodes, setCountryCodes] = useState([]);
  const [filteredCountries, setFilteredCountries] = useState([])
  const { setUserImage } = useContext(UserContext);
  const nodeAppUrl = process.env.REACT_APP_NODE_API_URL;

  useEffect(() => {
    axios.get('https://restcountries.com/v3.1/all')
      .then(response => {
        const countries = response.data.map(country => ({
          label: country.name.common,
          code: country.idd.root + (country.idd.suffixes ? country.idd.suffixes[0] : '')
        }));
        setCountryCodes(countries);
        setFilteredCountries(countries);
      })
      .catch(error => console.error('Error fetching country codes:', error));
  }, []);

  useEffect(() => {
    const filtered = countryCodes
      .filter(country =>
        country.label.toLowerCase().startsWith(searchQuery.toLowerCase()) ||
        country.code.startsWith(searchQuery)
      )
      .concat(
        countryCodes.filter(country =>
          !country.label.toLowerCase().startsWith(searchQuery.toLowerCase()) &&
          !country.code.startsWith(searchQuery) &&
          (country.label.toLowerCase().includes(searchQuery.toLowerCase()) ||
            country.code.includes(searchQuery))
        )
      );
    setFilteredCountries(filtered);
  }, [searchQuery, countryCodes]);


  const handleInputChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
    if (field === "countryCode") {
      setSelectedCountryCode(value);
    }
  };
  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];

    const reader = new FileReader();

    reader.onload = async () => {
      setImageFile(reader.result);
      setUserImage(reader.result);
    };
    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
      setFile(selectedFile);
      setInputValue(selectedFile.name);
      // const formData = new FormData();
      // formData.append('image', selectedFile);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${nodeAppUrl}/api/user`, {
          withCredentials: true,
        });
        const userDetails = response.data.personalDetails;
        const fetchedData = {
          firstName: userDetails.firstName,
          lastName: userDetails.lastName,
          businessName: response.data.businessName,
          countryCode: userDetails.countryCode,
          phoneNumber: userDetails.phoneNumber,
          email: response.data.userId,
          address: userDetails.address || "",
          state: userDetails.state || "",
          zipcode: userDetails.zipcode || "",
          country: userDetails.country || "",
        };
        setFormData(fetchedData);
        setInitialFormDataFetched(fetchedData); // Set initial fetched data

        if (response.data.personalDetails.userImage === "") {
          // If userImage is an empty string
          setImageFile(initialProfile);
          setInitialProfile(initialProfile);
          setUserImage(initialProfile);
        } else if (
          response.data.personalDetails.userImage.startsWith("https")
        ) {
          // If the userImage URL contains https
          setImageFile(response.data.personalDetails.userImage);
          setInitialProfile(response.data.personalDetails.userImage);
          setUserImage(response.data.personalDetails.userImage);
        } else {
          // If the userImage URL does not contain https
          const imageUrl = `${nodeAppUrl}/uploadUserProfiles/${response.data.personalDetails.userImage}`;
          setImageFile(imageUrl);
          setInitialProfile(imageUrl);
          setUserImage(imageUrl);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    fetchUserData();
  }, [nodeAppUrl]);

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const data = new FormData();

    data.append("firstName", formData.firstName);
    data.append("lastName", formData.lastName);
    data.append("email", formData.email);
    data.append("businessName", formData.businessName);
    data.append("countryCode", formData.countryCode);
    data.append("phoneNumber", formData.phoneNumber);
    data.append("address", formData.address);
    data.append("state", formData.state);
    data.append("zipcode", formData.zipcode);
    data.append("country", formData.country);

    // Add the image file to the FormData object
    // setFile(imageFile)
    console.log("File", file);
    if (imageFile) {
      data.append("image", file);
    }

    try {
      const response = await axios.post(`${nodeAppUrl}/api/saveDetails`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      });

      console.log("Response from the server:", response.data);
      if (response.data.success) {
        if (response.data.imageUrl) {
          setImageFile(
            `${nodeAppUrl}/uploadUserProfiles/${response.data.imageUrl}`
          );
        }
      }
      toast.success(response.data.message);
    } catch (error) {
      console.error("Error while saving details:", error);
    }
  };
  const handleReset = () => {
    setFormData(initialFormDataFetched);
    setImageFile(initialProfile);
    setUserImage(initialProfile)
  };

  // const {
  //   control,
  //   handleSubmit,
  //   formState: { errors },
  // } = useForm({ defaultValues: { checkbox: false } });
  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Card>
          <Typography gutterBottom sx={{ mb: 3, pt:3, pl: 3 }} className="primary-heading">
              Account Details
          </Typography>
          <form autoComplete="off" onSubmit={handleFormSubmit}>
            <CardContent sx={{ pt: 4, pb: 4 }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ImgStyled
                  src={imageFile || '/images/pages/profile-picture.png'}
                  alt="Profile Pic"
                  sx={{ ml: 6 }}
                />
                <div>
                  <ButtonStyled
                    className="primary-button"
                    component="label"
                    variant="contained"
                    htmlFor="account-settings-upload-image"
                    sx={{ fontWeight: 700, fontSize: 12 }}
                  >
                    Upload New Photo
                    <input
                      hidden
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      id="account-settings-upload-image"
                    />
                  </ButtonStyled>
                  {/* <ResetButtonStyled color="secondary" variant="outlined" onClick={handleInputImageReset}>
                    Reset
                  </ResetButtonStyled> */}
                </div>
              </Box>
            </CardContent>
            <CardContent>
              <Grid container spacing={5}>
                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    fullWidth
                    label="First Name"
                    placeholder="John"
                    value={formData.firstName}
                    onChange={(e) =>
                      handleInputChange("firstName", e.target.value)
                    }
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    fullWidth
                    label="Last Name"
                    placeholder="Doe"
                    value={formData.lastName}
                    onChange={(e) =>
                      handleInputChange("lastName", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    fullWidth
                    type="email"
                    label="Email"
                    value={formData.email}
                    disabled
                    placeholder="john.doe@example.com"
                    onChange={(e) => handleInputChange("email", e.target.value)}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    fullWidth
                    label="Business Name"
                    placeholder="Flywheel Infotech"
                    value={formData.businessName}
                    onChange={(e) =>
                      handleInputChange("businessName", e.target.value)
                    }
                    required
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <CustomTextField
                    select
                    label="Country Code"
                    value={formData.countryCode}
                    onChange={(e) =>
                      handleInputChange("countryCode", e.target.value)
                    }
                    variant="outlined"
                    style={{
                      width: "100px",
                      marginRight: "8px",
                      height: "56px",
                    }}
                    onInput={(e) => setSearchQuery(e.target.value)}
                    SelectProps={{
                      renderValue: (value) => `${value}`,
                    }}
                  >
                    {filteredCountries.map((option) => (
                      <MenuItem key={option.code} value={option.code}>
                        {`(${option.code}) ${option.label}`}
                      </MenuItem>
                    ))}
                  </CustomTextField>
                  <CustomTextField
                    label="Phone Number"
                    type="number"
                    variant="outlined"
                    value={formData.phoneNumber}
                    onChange={(e) =>
                      handleInputChange("phoneNumber", e.target.value)
                    }
                    inputProps={{ maxLength: 10 }}
                    style={{ width: "calc(100% - 108px)" }}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    fullWidth
                    label="Address"
                    placeholder="Address"
                    value={formData.address}
                    onChange={(e) =>
                      handleInputChange("address", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    fullWidth
                    label="State"
                    placeholder="California"
                    value={formData.state}
                    onChange={(e) => handleInputChange("state", e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    fullWidth
                    type="number"
                    label="Zip Code"
                    placeholder="Zip Code"
                    value={formData.zipcode}
                    onChange={(e) =>
                      handleInputChange("zipcode", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomTextField
                    fullWidth
                    label="Country"
                    placeholder="Country"
                    value={formData.country}
                    onChange={(e) =>
                      handleInputChange("country", e.target.value)
                    }
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ mt: 4 }}>
                <Button className="primary-button" type="submit" variant="contained" sx={{ mr: 3, fontWeight: 700, fontSize: 12 }}>
                  Save Changes
                </Button>
                <ResetButtonStyled
                  variant="outlined"
                  onClick={handleReset}
                  sx={{ fontWeight: 700, fontSize: 12}}
                >
                  Reset
                </ResetButtonStyled>
              </Grid>
            </CardContent>
          </form>
        </Card>
      </Grid>
      {/* <Grid item xs={12}>
        <Card>
          <CardHeader title="Delete Account" />
          <CardContent>
            <form>
              <Box sx={{ mb: 2 }}>
                <FormControl>
                  <Controller
                    name="checkbox"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <FormControlLabel
                        label="I confirm my account deactivation"
                        sx={{
                          "& .MuiTypography-root": {
                            color: errors.checkbox
                              ? "error.main"
                              : "text.secondary",
                          },
                        }}
                        control={
                          <Checkbox
                            {...field}
                            size="small"
                            name="validation-basic-checkbox"
                            sx={
                              errors.checkbox ? { color: "error.main" } : null
                            }
                          />
                        }
                      />
                    )}
                  />
                  {errors.checkbox && (
                    <FormHelperText
                      sx={{ color: "error.main" }}
                      id="validation-basic-checkbox"
                    >
                      Please confirm you want to delete account
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
              <Button
                variant="contained"
                color="error"
                type="submit"
                disabled={errors.checkbox !== undefined}
              >
                Deactivate Account
              </Button>
            </form>
          </CardContent>
        </Card>
      </Grid> */}
    </Grid>
  );
};

export default AccountSettings;
