import React, { useEffect, useState, Fragment, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../redux/UserContext';

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import axios from 'axios';

export default function AccountMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [avatarUrl, setAvatarUrl] = useState('/images/pages/profile-picture.png');
  const [name, setName] = useState('profile')
  const nodeAppUrl = process.env.REACT_APP_NODE_API_URL;
  const navigate = useNavigate();
  const { userImage, setUserImage,setSelectedTab } = useContext(UserContext);
 
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const handleLogout = async () => {
    try {

      await axios.post(`${nodeAppUrl}/api/logout`, {}, {
        withCredentials: true,
      });
      setSelectedTab("Dashboard");
      navigate('/login');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const handleAccountSettingsPage = () => {
    navigate('/account-settings');
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${nodeAppUrl}/api/user`, {
          withCredentials: true,
        });
        setName(response.data.personalDetails.firstName)
        if (response.data.personalDetails.userImage === "") {
          setAvatarUrl(avatarUrl);
          setUserImage(avatarUrl);
        } else if (
          response.data.personalDetails.userImage.startsWith("https")
        ) {
          setAvatarUrl(response.data.personalDetails.userImage);
          setUserImage(response.data.personalDetails.userImage);
        } else {
          const imageUrl = `${nodeAppUrl}/uploadUserProfiles/${response.data.personalDetails.userImage}`;
          setAvatarUrl(imageUrl);
          setUserImage(imageUrl);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() =>{
    setAvatarUrl(userImage)
  }, [userImage])

  return (
    <Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }} src={avatarUrl} />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem sx={{'&:hover': {backgroundColor: 'unset', cursor: 'default'}}}>
          <Avatar src={avatarUrl} /> {name}
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleAccountSettingsPage}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Account Settings
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </Fragment>
  );
}
