import React, { useEffect } from "react";
import MiniDrawer from "./MiniDrawer";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const MainLayout = ({ children }) => {
  const navigate = useNavigate();
  const nodeAppUrl = process.env.REACT_APP_NODE_API_URL;

  useEffect(()=>{
    const isTokenExists = async () => {
      try {
          const response = await axios.post(`${nodeAppUrl}/api/isTokenExists`, {}, {
              withCredentials: true,
          });

          if (response.status === 200) {
              console.log(response.data.message);
          } else {
              console.log('Token does not exist, redirecting to login page');
              navigate('/login');
          }
      } catch (error) {
          console.error("Error checking token existence", error.response?.data?.message || error.message);
          navigate('/login');
      }
    };
    isTokenExists();
  },[])

  return <MiniDrawer>{children}</MiniDrawer>;
};

export default MainLayout;
