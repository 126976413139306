import React, { useEffect, useState, useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import axios from "axios";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useNavigate } from "react-router-dom";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { IconButton, Tooltip } from "@mui/material";
import toast from "react-hot-toast";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { UserContext } from "../redux/UserContext";

export default function IntegrateAIAssistant() {
  const [script, setScript] = useState("");
  const [chatbotStatus, setChatbotStatus] = useState("");
  const { setSelectedTab } = useContext(UserContext);
  const nodeAppUrl = process.env.REACT_APP_NODE_API_URL;
  const navigate = useNavigate();

  useEffect(() => {
      setSelectedTab("AI Assistant")
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${nodeAppUrl}/api/user`, {
          withCredentials: true,
        });

        setScript(response.data.businessDetails.chatbot_script);
        setChatbotStatus(response.data.businessDetails.chatbot_status);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  const handleCopy = () => {
    navigator.clipboard.writeText(script);
    toast.success("Script copied to clipboard!");
  };

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: chatbotStatus === "Pending" ? "red" : "green",
      color: chatbotStatus === "Pending" ? "red" : "green",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));

  return (
    <Box>
      <Tabs value={1} onChange={() => navigate("/create-ai-assistant")} aria-label="tabs example"
          sx={{
            '& .MuiTabs-indicator': {
              backgroundColor: '#696cff',
            }
          }}
        >
          <Tab
            label="Create AI Assistant"
            sx={{
              color: 'gray',
              '&.Mui-selected': {
                color: '#696cff',
              }
            }}
          />
        <Tab
          label="Integrate AI Assistant"
          sx={{
            color: 'gray',
            '&.Mui-selected': {
              color: '#696cff',
            }
          }}
        />
      </Tabs>
      <div className="md:flex items-center justify-center px-3 py-1 w-full mt-6">
        <Card className="w-full md:w-2/3 min-h-60 md:flex flex-col justify-center md:p-[20px] p-[10px]" style={{ boxShadow: "0 2px 4px rgba(0,0,0,0.2)" }}>
          <CardContent>
            <Typography gutterBottom className="primary-heading">
                Chatbot Integration Script
                </Typography>
            <div className="flex md:flex-row flex-col items-end pt-3">
              <div className="pe-4 overflow-x-auto break-words w-[250px] md:w-full">
                <textarea 
                  className="w-full h-30 p-4 border rounded-md secondary-content" 
                  value={script}
                  readOnly
                />
              </div>
              <Tooltip title="Copy to clipboard" sx={{marginTop: "10px"}}>
                <IconButton onClick={handleCopy} color="primary">
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            </div>
          </CardContent>
          <CardActions className="md:flex md:flex-row flex-col justify-between">
            <Typography className="px-2 secondary-content">
              Your chatbot will be live within 3-4 hours. Please wait...
            </Typography>
            <Typography sx={{fontWeight: "bold"}}>
              Not live
              <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              variant="dot"
              className="m-3"
            >
            </StyledBadge>
            </Typography>
          </CardActions>
        </Card>
        <Card className="w-full md:w-1/3 md:m-3 my-4 min-h-60 flex md:flex flex-col justify-center md:p-[20px] p-[10px]" style={{ boxShadow: "0 2px 4px rgba(0,0,0,0.2)" }}>
          <CardContent>
            <Typography gutterBottom className="primary-heading" sx={{pb: 3}}>
              Chatbot Status
            </Typography>
            <Typography className="secondary-content">
              Indexing: Pending
            </Typography>
            <Typography className="secondary-content" sx={{pt:1}}>
              Status: Not Approved
            </Typography>
          </CardContent>
        </Card>
      </div>
    </Box>
  );
}
